import React, { useEffect, useState } from "react";
import "../assets/css/subscription.css";
import apiService from "../core/apiService";
import { Spinner } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import { useSelector } from "react-redux";
const Subscription = ({ selectedPlan, changePlan = () => {}, msg = 1 }) => {
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [allSubscriptions, setAllSubscriptions] = useState([]);
  const subscribe = useSelector((state) => state.subscribe);

  const [type, setType] = useState("monthly");
  const [currentPlan, setCurrentPlan] = useState();
  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [stripeid , setStripeid ]= useState('')
  const plans = [
    "",
    "Basic Monthly",
    "Premium Monthly",
    "Basic Yearly",
    "Premium Yearly",
  ];
  useEffect(() => {
    if(subscribe){
    setCurrentPlan(selectedPlan);
    setStripeid(subscribe.stripe_subscription_id)}
  }, [selectedPlan]);
  console.log(subscribe, "subscribesubscribe");
  useEffect(() => {
    setDataLoading(true);
    apiService.get(`/subscriptions/get-subscriptions`, {}, ({ err, data }) => {
      if (err) return console.log(err);
      setAllSubscriptions(data.data);
      setSubscriptionList(
        data.data.filter(
          (subscription) => subscription.subscription_type === type
        )
      );
      setDataLoading(false);
    });
  }, []);

  const selectPlan = (_plan) => {
    setCurrentPlan(_plan);
  };
  // console.log(currentPlan);
  const onPlanChange = () => {
    if (!currentPlan) return;
    setLoading(true);
    changePlan({ selectedplan: currentPlan });
  };

  const classList = (_type) => {
    return _type === type ? "nav-link active" : "nav-link";
  };

  const filterData = (type) => {
    setType(type);
    setSubscriptionList(
      allSubscriptions.filter(
        (subscription) => subscription.subscription_type === type
      )
    );
  };

  const submitPlan = () => {
    confirmAlert({
      message:
        msg === 2
          ? `Are you sure to change your plan from ${
              plans[selectedPlan?.id]
            } to ${plans[currentPlan?.id]} 
            Click OK to confirm or Cancel to go back.
            `
          : `Are you sure you want to proceed with the ${
              plans[currentPlan?.id]
            } Plan.`,
      buttons: [
        {
          label: "OK",
          onClick: () => onPlanChange(),
        },
        {
          label: "Cancel",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const subscriptionContent = dataLoading ? (
    <Spinner />
  ) : (
    <div className="row">
      <div className="col-xl-2"></div>

      {subscriptionList.map((subscription, i) => (
        <React.Fragment key={i}>
          <div className="col-lg-6 col-xl-4">
            <div
              className={`plan-box txt-poppin ${
                currentPlan?.id === subscription?.id ? "succes-plan-box" : ""
              }`}
            >
              <span className="plan-tag">{subscription.subscription_name}</span>
              <div className="plan-price">
                <span className="price-con">
                  ${subscription.subscription_price}
                </span>
                <span>{type === "monthly" ? "month" : "year"}</span>
              </div>
              <ul className="plan-point">
                {subscription.subscriptionfeatures.map((subscriptionf, i) =>
                  subscriptionf.enable ? (
                    <li>
                      {/* <input class="form-check-input" type="checkbox" value="" id={(subscriptionf.enable)?"flexCheckChecked":"flexCheckDefault"} checked/> */}
                      {!!subscriptionf.enable ? (
                        <input
                          type="checkbox"
                          className="form-check-input mx-2"
                          checked
                          disabled
                        />
                      ) : (
                        <input
                          className="form-check-input  mx-2"
                          type="checkbox"
                          value=""
                          disabled
                          id="flexCheckDefault"
                        />
                      )}
                      <span className="mt-2">
                        {" "}
                        {subscriptionf.feature.feature_name}
                      </span>
                    </li>
                  ) : (
                    <></>
                  )
                )}
              </ul>
              <button
                disabled={subscription.id === currentPlan?.id}
                onClick={() => selectPlan(subscription)}
                className={`btn ${
                  subscription.id === currentPlan?.id
                    ? "btn-dark"
                    : "gray-transparent-btn"
                }`}
              >
                Choose Plan
              </button>
            </div>
          </div>
        </React.Fragment>
      ))}
    </div>
  );

  return (
    <div className="membership_plans">
      <div className="container mt-5">
        <div className="plans-grid">
          <ul className="nav nav-tabs" id="plan-tabs" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                onClick={() => filterData("monthly")}
                className={classList("monthly")}
              >
                Monthly
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                onClick={() => filterData("yearly")}
                className={classList("yearly")}
              >
                Yearly
              </button>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            {subscriptionContent}
            <div className="continue-btn" style={{ textAlign: "center" }}>
              <button
                disabled={ stripeid ? (!currentPlan  || (selectedPlan?.id === currentPlan?.id )):false}
                onClick={submitPlan}
                // {onPlanChange}
                className="btn btn-dark payment-btn"
              >
                {loading ? <Spinner /> : "Continue to Pay"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscription;
