import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useState } from "react";
import apiService from '../../core/apiService';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import logo from '../../assets/images/logo.png';
import sucessTick from '../../assets/images/sucess-tick.png';

function CheckoutForm() {

  const navigate = useNavigate();
  const location = useLocation();
  const restaurant = useSelector(state => state.restaurant);
  const stripe = useStripe();
  const elements = useElements();
  const [isPayment, setisPayment] = useState(false);
  const fromPage = location.state?.from?.pathname || '/dashboard';
  const [clientSecret] = useState(location.state.clientSecret);

  if (!stripe || !elements) {
    // Stripe.js has not loaded yet. Make sure to disable
    // form submission until Stripe.js has loaded.
    return '';
  }

  const handleSubscriptionSubmit = async (e) => {

    e.preventDefault();
    const cardElement = elements.getElement(CardElement);

    let { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret.toString(), {
      payment_method: {
        card: cardElement,
        billing_details: {
          name: restaurant?.restaurant_name,
        }
      }
    });
    if (error) {
      // setMessage(error.message);
      console.log(error);
      return;
    }

    setisPayment(isPayment === false ? true : false);

    apiService.post(
      `/restaurants/verify/update/${restaurant.id}`,
      {},
      ({ err, data }) => {
        if (err) {
          console.log(err);
        }
       
   
      }
    );
    setTimeout(() => {
      navigate(fromPage, { replace: true })
    }, 2000);
  }

  const CARD_ELEMENT_OPTIONS = {
    iconStyle: 'solid',
    style: {
      base: {
        iconColor: '#8898AA',
        color: 'white',
        lineHeight: '36px',
        fontWeight: 300,
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '19px',
  
        '::placeholder': {
          color: '#8898AA',
        },
      },
      invalid: {
        iconColor: '#e85746',
        color: '#e85746',
      }
    },
    classes: {
      focus: 'is-focused',
      empty: 'is-empty',
    }
  };
  return ( 
    <>
      <form onSubmit={handleSubscriptionSubmit}>
        <div className="row">
          <div className="col-sm-12">
            <div className="form-group mb-4">
              <input
                type="text"
                className="form-control"
                name="discountPrice"
                value={restaurant?.restaurant_name}
              />
            </div>
            <div className="form-group mb-4">
              <CardElement />
            </div>
            <button class="btn btn-dark full-btn btn-lg signin-btn">
              Submit
            </button>
          </div>
        </div>
      </form>

      {isPayment === true ?
        <>
          <div className="common-message-pop show" id="payment-success-pop">
            <div className="common-pop-inner">
              <div className="common-pop-box">
                <div className="popup-logo">
                  <img alt="" src={logo} />
                </div>
                <div className="message-icon">
                  <img alt="" src={sucessTick} />
                </div>
                <p className="message-txt">Payment Successful!!</p>
              </div>
            </div>
          </div>
        </> : <></>
      }
    </>
  );
}

export default CheckoutForm;