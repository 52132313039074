import React, { useEffect, useRef, useState, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import logo from '../assets/images/logo.png';
import hamburgermenu from '../assets/images/hamburgermenu.png';
import profile from '../assets/images/profile.png';
import IcChat from '../assets/images/ic_chat.svg';
import IcBell from '../assets/images/ic_bell.svg';
import apiService from '../core/apiService';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { click } from '@testing-library/user-event/dist/click';



const TopNav = ({ refresh, handleClick }) => {

	const [_, setCookie, removeCookie] = useCookies(['userId', 'userToken', 'restaurantId']);
	const userData = useSelector(state => state.userData);
	const restaurant = useSelector(state => state.restaurant);
	const [dashboardDetails, setDashboardDetails] = useState([])
	const [isProfileAction, setisProfileAction] = useState(false);
	const [showmessage, setShowMessages] = useState(false)
	const navigate = useNavigate();
	const showProfileAction = () => {
		setisProfileAction(isProfileAction === false ? true : false);
	};
	const dispatch = useDispatch()

	const location = useLocation();
	const idleTimeout = 60 * 60 * 1000; // 60 minutes in milliseconds
	const idleTimeoutRef = useRef(null);
	const [logoutTimer, setLogoutTimer] = useState(null);
	const baseUrl = process.env.REACT_APP_URL + '/public/';
	const [isLoggedIn, setIsLoggedIn] = useState(true);


	function removeAllCookies() {
		var cookies = document.cookie.split(";");

		for (var i = 0; i < cookies.length; i++) {
			var cookie = cookies[i];
			var eqPos = cookie.indexOf("=");
			var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
			document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
		}
	}

	// Example usage:
	// removeAllCookies();

const handle =()=>{
	navigate('/request-admin', { replace: true })
}

	const onLogout = () => {

		confirmAlert({
			title: 'Confirm logout',
			message: 'Are you sure you want to log out',
			buttons: [
				{
					label: 'Yes',
					onClick: () => {
						navigate('/', { replace: true })
						removeAllCookies()
						dispatch({
							type: 'LOGOUT'
						});
						removeCookie('userId');
						removeCookie('restaurantId');
						removeCookie('userToken');
						removeCookie('userAccess');

						
						// Optionally, you can perform additional logout actions here
					},
				},
				{
					label: 'No',
					//onClick: () => alert('Click No')
				}
			]
		});
	};
	const logout = () => {
		navigate('/', { replace: true })
		removeAllCookies();
		dispatch({ type: 'LOGOUT' });
		removeCookie('userId');
		removeCookie('restaurantId');
		removeCookie('userToken');
	}

	// let timeout;
	// useEffect(()=>{
	// 	timeout = setTimeout(logout, idleTimeout);
	// })
	// function myEventHandler(event) {


	// 	clearTimeout(timeout);
	// 	timeout = setTimeout(logout, idleTimeout);
	// }


	// ["mousedown", "mousemove", "keypress", "scroll", "touchstart","resize",].forEach(event => {
	// 				window.addEventListener(event, myEventHandler);
	// 	 		});
	const activityTimer = useRef(null);

	useEffect(() => {
		const resetTimer = () => {
			if (activityTimer.current) clearTimeout(activityTimer.current);
			activityTimer.current = setTimeout(() => {
				// Perform logout action here
				console.log("Session timed out, logging out...");
				logout()

			}, 60 * 60 * 1000);
		};

		const handleUserActivity = () => {
			resetTimer();
		};

		// Attach event listeners to the entire document
		document.addEventListener('mousemove', handleUserActivity);
		document.addEventListener('keypress', handleUserActivity);


		resetTimer();

		return () => {
			document.removeEventListener('mousemove', handleUserActivity);
			document.removeEventListener('keypress', handleUserActivity);
			clearTimeout(activityTimer.current);
		};
	}, []);



	useEffect(() => {
		apiService.get(`/restaurants/dashboard/${restaurant.id}`, {}, ({ err, data }) => {
			if (data.success) {

				setDashboardDetails(data.data);
				console.log(data.data, "data1")
				// dispatch({ type: "UPDATE_RESTAURANT", payload: data.data.restaurant });

			} else {
				console.log(err);
			}
		});
		apiService.get(`/get-restaurant`, { id: restaurant.id }, ({ err, data }) => {
			if (err) return console.log(err);
			// userData.restaurant = { ...data.data };
			console.log(data.data, 'restaurant')
			dispatch({ type: "UPDATE_RESTAURANT", payload: data.data });

		});

	}, [refresh])





	let heading = '';
	let loc = location.pathname.split('/');
	if (loc[1] === 'dashboard') {
		heading = 'Dashboard';
	} else if (loc[1] === 'daily-special') {
		heading = 'Daily Specials';
	} else if (loc[1] === 'game-day-special') {
		heading = 'Game Day Specials';
	} else if (loc[1] === 'happy-hour') {
		heading = 'Happy Hours';
	} else if (loc[1] === 'on-tap') {
		heading = 'On Tap';
	} else if (loc[1] === 'request-admin') {
		heading = 'Request to Admin';
	} else if (loc[1] === 'communication') {
		heading = 'Communication';
	} else if (loc[1] === 'profile-setting') {
		heading = 'Profile Setting';
	} else if (loc[1] === 'profile-membership') {
		heading = 'Profile Membership';
	}



	return (
		<div className="top-nav">
			<div className="dashboard-logo">
				<Link to='/dashboard'><img alt="" src={logo} className="small-logo" /></Link>
			</div>
			<button className="toggle-menu" onClick={handleClick} ><img alt="" src={hamburgermenu} /></button>
			<h3 className="page-name">{heading}</h3>
			<div className="profile-info">
				<div className='profile-info-box' onClick={showProfileAction}>
					<img alt="" src={restaurant?.restaurant_pic ? baseUrl + restaurant?.restaurant_pic : profile} />
					<div className="profile-name">
						<h5>{userData?.fullName || userData?.username}</h5>
						<span>Welcome</span>
					</div>
					<span className="profile-icon">
						<FontAwesomeIcon icon={faAngleDown} />
						<i className="fas fa-angle-down"></i>
					</span>
				</div>
				{isProfileAction === true ?
					<>
						<div className="profile-tooltip show">
							<Link onClick={showProfileAction} to='/profile-setting'>Profile Setting</Link>
							<Link onClick={onLogout} >Logout</Link>
						</div>
					</> : <></>
				}
			</div>

			<div className="message-notificaion" onClick={() => { setShowMessages(prev => !prev) }}   >
				{
					dashboardDetails?.reply?.length ? <div className="notification-icon" title="General Request from Restaurant" >
						<img alt="" src={IcBell} />
						<span></span>
					</div> : <div className="notification-icon" title="General Request from Restaurant" >
						<img alt="" src={IcBell} />
						{/* <span></span> */}
					</div>
				}
				{/* <div className="notification-icon" title="General Request from Restaurant" >
					<img alt="" src={IcBell} />
					<span></span>
				</div> */}
				{showmessage === true ?
					<div className="notification-pop" onMouseLeave={() => { setShowMessages(false) }}>
						<div className="notificaion-inner">
							{/* <h4 className="notification-title">Today</h4>
							<span className="unread-noti-btn">Unread(3)</span> */}
							{dashboardDetails?.reply?.length ?
								<>{
									dashboardDetails?.reply?.map(item => {

										return <div className="notificaion-box">
											{/* <img alt="" src={profile} /> */}

											<div className="notification-con">
												{
													item.type_id == 3 ?
												<p> Admin has {` ${((item?.status == 1) ? 'Replied' : "")} your Request for  ${item?.subject}`}</p>:
												<p> Admin has {` ${((item?.status == 1) ? 'Accepted' : "Rejected")} your Request for  ${item?.subject}`}</p>


												}
												<div className="notifaction-date-time">
													<span><i className="far fa-clock"></i> {
														moment(item.updated_at).format('YYYY-MM-DD, hh:mm')}</span>
												</div>
											</div>
											
										</div>
									})}
									<div style={{cursor:'pointer',float:'right',color:'#b72727'}} onClick={handle}>See more</div>
									
									</> : <div className="notificaion-box">
									<p>No notifications yet</p>
								</div>


							}
							{/* <h4 className="notification-title">This week</h4> */}
						</div>
					</div>
					: <></>
				}
			</div>
		</div>

	)

}


export { TopNav };