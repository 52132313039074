import React, { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import apiService from "../../core/apiService";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../assets/images/logo.png";
import sucessTick from "../../assets/images/sucess-tick.png";
import { Spinner } from "react-bootstrap";
import Subscription from "../../components/Subscription";
import { toast } from "react-toastify";
import CustomError from "../../components/CustomError";
import AdminSubscription from "../../components/AdminSubscription";

const Membership = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { search } = useLocation();
  const promocode = new URLSearchParams(search).get("promoCode");
  const error = new URLSearchParams(search).get("error");
  const sessionId = new URLSearchParams(search).get("session_id");
  const restaurant = useSelector((state) => state.restaurant);
  const location = useLocation();
  const subscribe = useSelector((state) => state.subscribe);
  const fromPage = location.state?.from?.pathname || "/dashboard";
  const [isPayment, setisPayment] = useState(false);
  const [isCheckout, setisCheckout] = useState(false);
  const [plan, setPlan] = useState("");
  const [isDisabled, setDisabled] = useState(false);
  const [customerId, setCustomerID] = useState("");
  const [productId, setProductId] = useState("");
  const [subscriptionList, setSubscriptionList] = useState([]);
  const dispatch = useDispatch();
  const handleSubmit = ({ selectedplan, selectPlan }) => {
    console.log(selectPlan, "djdjkhdkjjksdj");
    setLoading(true);

    try {
      const data = {
        restaurant_id: restaurant?.id,
        subscription_id: selectedplan?.id,
        restaurant_subscription_end_date: selectPlan,
      };

      apiService.SubscriptionPost(
        "/stripe/create-customer-byAdmin",
        data,
        ({ err, data }) => {
          if (err) return console.log("Error: " + err);
          console.log(data, "data.datdfdffa");

          console.log(data.data.customer, "custom");
          apiService.get(
            `/restaurants/subscription/getpackage`,
            { restaurant_id: restaurant?.id },
            ({ err, data }) => {
              if (err) return console.log(err);
              dispatch({
                type: "SET_SUBSCRIBE",
                payload: data.data,
              });

              console.log(
                data.data.subscription.id,
                "data.data.subscription.id"
              );
              setCustomerID(data.data.subscription.id);
              setLoading(false);
            }
          );
        }
      );
    } catch (e) {}
  };

  const changePlan = (id, product) => {
    setPlan(id);
    setProductId(product);
    setDisabled(true);
  };
  console.log(customerId, "customerIdcustomerIdcustomerId");
  if (customerId) return <Navigate to="/dashboard" replace={true} />;

  return (
    <div style={{ position: "relative" }}>
      <div id="verification-page">
        <div className="container">
          <div className="verification-logo">
            <img alt="" src={logo} />
            <p>Select Your Membership</p>
          </div>

          <AdminSubscription changePlan={handleSubmit} />
        </div>
      </div>
      {isPayment === true ? (
        <>
          <div className="common-message-pop show" id="payment-success-pop">
            <div className="common-pop-inner">
              <div className="common-pop-box">
                <div className="popup-logo">
                  <img alt="" src={logo} />
                </div>
                <div className="message-icon">
                  <img alt="" src={sucessTick} />
                </div>
                <p className="message-txt">Payment Successful!!</p>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Membership;
