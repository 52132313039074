import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleNotch,
  faEye,
  faEyeSlash,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import apiService from "../../core/apiService";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import { useCookies } from "react-cookie";
import logo from "../../assets/images/logo.png";
import moment from "moment";
import { Alert } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { confirmAlert } from "react-confirm-alert";
import CustomSuccess from "../../components/CustomSuccess";
const RegisterByAdmin = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const [promoCodeError, setPromoCodeError] = useState("");
  const [_, setCookie] = useCookies(["userId", "userToken", "restaurantId"]);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm();
  const profilePic = watch("restaurant_profile_pic", false);
  const [uniqueUsername, setUniqueUsername] = useState(true);
  const [restaurantEmail, setRestaurantEmail] = useState(true);
  const [passwordError, setPasswordError] = useState(true);
  const [passwordErrorMsg, setPasswordErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const fromPage = location.state?.from?.pathname || "/admin-verify-business";
  const [imageType, setImageType] = useState(true);
  const [isVisible, setVisible] = useState(true);
  const [numberAvailabel, setNumberAvailabel] = useState(false);
  const [uppercaseAvailabel, setUppercaseAvailabel] = useState(false);
  const [lowercaseAvailabel, setLowercaseAvailable] = useState(false);
  const [specialcharAvailabel, setSpecialcharAvailabel] = useState(false);
  const [passLengthSatisfy, setPassLengthSatisfy] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const state = useSelector((state) => state);
  console.log(state, "checking st");

  useEffect(() => {
    let promoCode = searchParams.get("promoCode");
    if (!promoCode) return;
    apiService.get("/promotions/get", { promoCode }, ({ err, data }) => {
      console.log(err, data);
      if (err)
        return setPromoCodeError(
          "Promo code is invalid. However, you can still continue with registration."
        );
      if (moment(data.data.expire_at).isBefore(moment()))
        return setPromoCodeError(
          "Oops, looks like promo code is expired. However, you can still continue with registration."
        );
      setValue("restaurant.promoCode", data.data.promoCode);
    });
  }, []);

  const toggle = () => {
    setVisible(!isVisible);
  };
  const getUserName = useCallback(
    debounce((e) => {
      if (!e.target.value) return;
      apiService.get(
        "/get-user",
        { username: e.target.value, enabled: 1 },
        ({ err, data }) => {
          if (data) {
            setUniqueUsername(false);
          } else {
            setUniqueUsername(true);
            const value = e.target.value;
            const newPassword = `${value}@123`;
  
            setValue("restaurant.restaurant_email", `${value}@whatsonlogin.com`);
            setValue("password", newPassword);
  
            // Trigger password validation for the updated password
            handlePassword({ target: { value: newPassword } });
          }
        }
      );
    }, 500),
    []
  );
  

  const getRestaurantEmail = useCallback(
    debounce((e) => {
      if (!e.target.value) return;
      apiService.get(
        "/get-restaurant",
        { restaurant_email: e.target.value, email_verified: 1 },
        ({ err, data }) => {
          if (data) {
            setRestaurantEmail(false);
          } else {
            setRestaurantEmail(true);
          }
        }
      );
    }, 500),
    []
  );

  const handlePassword = useCallback(
    debounce((e) => {
      if (!e.target.value) return;
      const uppercaseRegExp = /(?=.*?[A-Z])/;
      const lowercaseRegExp = /(?=.*?[a-z])/;
      const digitsRegExp = /(?=.*?[0-9])/;
      const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
      const minLengthRegExp = /.{8}/;
      const uppercasePassword = uppercaseRegExp.test(e.target.value);
      const lowercasePassword = lowercaseRegExp.test(e.target.value);
      const digitsPassword = digitsRegExp.test(e.target.value);
      const specialCharPassword = specialCharRegExp.test(e.target.value);
      const minLengthPassword = minLengthRegExp.test(e.target.value);

      if (e.target.value == "") {
        setPasswordErrorMsg("Password is empty");
        setPasswordError(true);
        return;
      }
      if (uppercasePassword) {
        setUppercaseAvailabel(true);
      }
      if (specialCharPassword) {
        setSpecialcharAvailabel(true);
      }
      if (lowercasePassword) {
        setLowercaseAvailable(true);
      }
      if (digitsPassword) {
        setNumberAvailabel(true);
      }
      if (!uppercasePassword) {
        setPasswordErrorMsg("At least one Uppercase");
        setPasswordError(true);
        setUppercaseAvailabel(false);
        return;
      }

      if (!lowercasePassword) {
        setPasswordErrorMsg("At least one Lowercase");
        setPasswordError(true);
        setLowercaseAvailable(false);
        return;
      }
      if (!specialCharPassword) {
        setPasswordErrorMsg("At least one Special Characters");
        setPasswordError(true);
        setSpecialcharAvailabel(false);
        return;
      }

      if (!digitsPassword) {
        setPasswordErrorMsg("At least one digit");
        setPasswordError(true);
        setNumberAvailabel(false);
        return;
      }

      if (!minLengthPassword) {
        setPasswordErrorMsg("At least minumum 8 characters");
        setPasswordError(true);
        setPassLengthSatisfy(false);
        return;
      }
      if (minLengthPassword) {
        setPassLengthSatisfy(true);
      }
      setPasswordErrorMsg("");
      setPasswordError(false);
      return;
    }, 500),
    []
  );

  const onSubmit = (data) => {
    if (!uniqueUsername) return;
    if (!restaurantEmail) return;
    setLoading(true);
    apiService.postRegisterByAdmin(
      "/admin/restaurant-regiter",
      data,
      ({ err, data }) => {
        console.log(err, data);
        if (err) {
          console.log(err);
          setLoading(false);
          return;
        }
        setLoading(false);
        setCookie("userAccess",btoa("Admin"))
        setCookie("userId", data.data.id, { maxAge: 24 * 60 * 60 });
        setCookie("userToken", data.data.token, {
          maxAge: 24 * 60 * 60,
          path: "/",
        });
        setCookie("restaurantId", data.data.restaurant.id, {
          maxAge: 24 * 60 * 60,
          path: "/",
        });
        dispatch({
          type: "onRegister",
          payload: data.data,
        });
        dispatch({
          type: "onStep",
          payload: 1,
        });
        window.localStorage.setItem("step", 1);
        let promoCode = searchParams.get("promoCode");
        if (promoCode) {
          navigate(`${fromPage}?promoCode=${promoCode}`, { replace: true });
          return;
        } else {
          // CustomSuccess("Otp sent to your email", "Success");

          navigate(fromPage, { replace: true });
        }
      },
      { isFormData: true }
    );
  };

  const fileUpload = (e) => {
    const maxSize = 256000; // 1MB in bytes
    if (!e.target.files[0].type.includes("image")) {
      setErrorMessage("Only image are allowed");
      setImageType(false);
      return;
    }
    if (e.target.files[0].size > maxSize) {
      setErrorMessage("Image size should be upto 250kb");
      setImageType(false);
      return;
    }
    setImageType(true);
    console.log(e.target.files[0], "e.target.files[0]");
    setValue("restaurant_profile_pic", e.target.files[0]);
  };

  return (
    <div>
      <div id="register-page">
        <div className="login-form">
          <div className="login-form-inner">
            <div className="login-logo">
              <img src={logo} alt="Whatson" />
            </div>
            <Alert variant="danger" show={promoCodeError ? true : false}>
              {promoCodeError}
            </Alert>
            <div className="login-form-box">
              <form autoComplete={"off"} onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-4">
                      <label>Username</label>
                      <input
                      maxLength={150}
                        {...register("username", {
                          required: "Username is required",
                          onChange: getUserName,
                        })}
                        type="text"
                        className="form-control"
                        placeholder="Username"
                      />
                      {errors.username && (
                        <span className="error password-error">
                          {errors.username?.message}
                        </span>
                      )}
                      {!uniqueUsername && !errors.username && (
                        <span className="error password-error">
                          Username is taken. Please try another one
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-4 password-input-field">
                      <label>Password</label>
                      <input
                      maxLength={100}
                        autoComplete="usernameeerere_password"
                        {...register("password", {
                          required: "Password is required",
                          onChange: handlePassword,
                        })}
                        // type={isVisible ? "password" : "text"}
                        className="form-control"
                        placeholder="Password"
                      />
                      <span className="icon Password-hide" onClick={toggle}>
                        {/* {isVisible ? (
                          <FontAwesomeIcon icon={faEyeSlash} />
                        ) : (
                          <FontAwesomeIcon icon={faEye} />
                        )} */}
                      </span>
                      {passwordError ? (
                        <span className="error password-error">
                          {passwordErrorMsg}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="mb-4">
                  <label>Restaurant Name</label>
                  <input
                    maxLength={250}
                    {...register("restaurant.restaurant_name", {
                      required: "Restautant name is required",
                    })}
                    type="text"
                    className="form-control"
                    placeholder="Restaurant Name"
                    aria-invalid={
                      errors.restaurant?.restaurant_name ? "true" : "false"
                    }
                  />

                  {errors.restaurant?.restaurant_name && (
                    <span className="error password-error">
                      {errors.restaurant?.restaurant_name?.message}
                    </span>
                  )}
                </div>
                <div className="mb-4">
                  <label>Restaurant Email</label>
                  <input
                  maxLength={150}
                    {...register("restaurant.restaurant_email", {
                      required: "Restaurant Email is required",
                      pattern: {
                        value: /^[a-zA-Z0-9._%+-]+@whatsonlogin\.com$/,
                        message: "Email must be a @whatsonlogin.com address",
                      },
                  
                      onChange: getRestaurantEmail,
                    })}
                    type="email"
                    id="email"
                    className="form-control"
                    placeholder="email@whatsonlogin.com"
                  />

                  {!restaurantEmail ? (
                    <span className="error password-error">
                      Email Already In Use..
                    </span>
                  ) : (
                    ""
                  )}
                  {errors.restaurant?.restaurant_email && (
                    <span className="error password-error">
                      {errors.restaurant?.restaurant_email?.message}
                    </span>
                  )}
                </div>
                <div className="mb-4 p-0">
                  <label>Phone Number</label>
                  <PhoneInput
                    country="ca"
                    onChange={(value) =>
                      setValue("restaurant.restaurant_phone_number", value)
                    }
                    type="tel"
                    id="contact"
                    className="form-control  border-0"
                  />

                  {errors.restaurant?.restaurant_phone_number && (
                    <span className="error password-error">
                      {errors.restaurant?.restaurant_phone_number?.message}
                    </span>
                  )}
                </div>

                <div className="mb-4">
                  <label>Instagram Link (Optional)</label>
                  <input
                  maxLength={300}
                    {...register("instagram_link")}
                    type="text"
                    id="instalink"
                    className="form-control"
                    placeholder="Instagram.com/omegao_rion/?hl=en"
                  />
                </div>
                <div className="mb-4">
                  <label>Tell us about your restaurant</label>
                  <textarea
                    {...register("restaurant.restaurant_about", {
                      required: "Restaurant is required",
                    })}
                    type="text"
                    className="form-control"
                    placeholder=" Tell us something about Restaurant"
                    aria-invalid={
                      errors.restaurant?.restaurant_about ? "true" : "false"
                    }
                  />

                  {errors.restaurant?.restaurant_about && (
                    <span className="error password-error">
                      {errors.restaurant?.restaurant_about?.message}
                    </span>
                  )}
                </div>
                <div className="mb-4">
                  {profilePic ? (
                    <div
                      className="img "
                      onClick={() => setValue("restaurant_profile_pic", null)}
                    >
                      <img
                        src={URL.createObjectURL(profilePic)}
                        className="update-img-colum"
                      />
                    </div>
                  ) : (
                    <>
                      <label>
                        Add a profile photo or logo of your restaurant
                        (Optional)
                      </label>
                      {/* <div className="mt-4  graph-set">
												<p><a href="https://www.adobe.com/in/express/feature/image/resize" target='_blank'>Click here to resize your image to the required dimensions</a></p>
												</div> */}
                      <div className="restaurant-photo-box">
                        <div className="restaurant-photo-input">
                          <FontAwesomeIcon icon={faPlus} />
                        </div>
                        <input
                          accept="image/png, image/jpeg , image/jpg"
                          onChange={fileUpload}
                          type="file"
                          className="photo-input"
                        />
                        {!imageType && (
                          <span className="error password-error">
                            {errorMessage}
                          </span>
                        )}
                      </div>
                    </>
                  )}
                </div>

                <div className="mt-4    graph-set">
                  <p>
                    Note:{" "}
                    <b>
                      {" "}
                      Please upload an image with a maximum file size of 250KB
                      and a recommended resolution (e.g. 540x320, 1800x1200)
                    </b>{" "}
                  </p>
                </div>
                <div className="mb-3">
                  <button
                    disabled={uniqueUsername && !passwordError ? "" : "true"}
                    className="btn btn-dark full-btn btn-lg signin-btn"
                  >
                    {loading ? (
                      <FontAwesomeIcon icon={faCircleNotch} spin />
                    ) : (
                      "Register"
                    )}
                  </button>
                </div>
              </form>
            </div>
            <div className="form-bottom-link">
              Already have an account?{" "}
              <Link to="/">
                <b>Login Now</b>
              </Link>
            </div>
          </div>
        </div>
        <div className="login-img">
          <div className="login-img-inner">
            <div className="dashboard-title">
              <h3>WELCOME TO</h3>
              <h1>Your Restaurant Dashboard</h1>
              <p>Register to Access Dashboard</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterByAdmin;
